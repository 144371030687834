import React from "react"
import { Link } from "gatsby"

import styles from "./postListItem.module.scss"

const PostListItem = (props) => {
  const type = props.type ? `${props.type}/` : ""
  let buttonLabel = "";

  if (props?.resource?.label) {
    buttonLabel = props.resource.label
  } else if (props.label) {
    buttonLabel = props.label
  } else {
    buttonLabel="Read the blog"
  }
  return (
    <li className={`${props.alt ? styles.postItemAlt : styles.postItem}`}>
      <article>
        <Link to={`${type}/${props?.category?.slug.current}/${props.slug.current}/`} aria-labelledby={`${props.slug.current}-title`} aria-describedby="Read the blog">
          {props._rawThumbnail && <div className={styles.postThumb} style={{backgroundImage: `url(${props._rawThumbnail.asset.url})`}}></div>}
          <div className={styles.postpropsInner}>
            <header>
              {props.category && <p className={styles.postCat}>{props.category.title}</p>}
              <h3 className={styles.postTitle} id={`${props.slug.current}-title`}>{props.title}</h3>
            </header>
            <p className={styles.postExcerpt}>{props.excerpt}</p>
            <footer className={styles.postButton}>
              <span>{buttonLabel}</span>
            </footer>
          </div>
        </Link>
      </article>
    </li>
  )
}

export default PostListItem
