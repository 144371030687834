import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import PostListItem from "../components/postListItem"
import FormattedLink from "../components/link"
import InsightCats from "../components/categoriesInsight"
import TechCats from "../components/categoriesTech"

import styles from "./blogs.module.scss"

export const query = graphql`
  query {
    sanityBlogSettings {
      heroTitleInsights
      descriptionInsights
      heroTitleTech
      descriptionTech
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    allSanityBlogInsight(sort: {fields: publishDate, order: DESC}, limit: 4) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
          excerpt
        }
      }
    }
    allSanityBlogTech(sort: {fields: publishDate, order: DESC}, limit: 4) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
          excerpt
        }
      }
    }
  }
`

const Blogs = (props) => {
  const blogs = props.data.sanityBlogSettings
  const blogInsight = props.data.allSanityBlogInsight.edges
  const blogTech = props.data.allSanityBlogTech.edges

  const customTitle = blogs.seo?.metaTitle ? blogs.seo.metaTitle : null
  const description = blogs.seo?.description ? blogs.seo.description : null
  const image = blogs.seo?.openGraphImage?.asset !== null ? blogs?.seo?.openGraphImage?.asset.url : null

  return (
    <Layout theme="light">
        <SEO
          title="Watershed Blogs"
          customTitle={customTitle}
          description={description}
          image={image}
      />
      <Hero heroTitle="Watershed Blogs" theme="light" graph="radar2" />
      <div className={styles.blogs}>
        <section className={styles.blogGrid} data-theme="brandLight">
          <div className={styles.blogGridHeader}>
            <h2><Link to="/blog/">{blogs.heroTitleInsights}</Link></h2>
            {blogs.descriptionInsights && <p className={styles.blogIntro}>{blogs.descriptionInsights}</p>}
          </div>
          <ul className={styles.blogGridList} data-theme="brandLight">
            {blogInsight.map(({node: item}) =>
              <PostListItem {...item} type="blog" />
            )}
          </ul>
          <div className={styles.blogGridLink}>
            <FormattedLink linkUrl="/blog/" linkLabel="See all our Insight blogs" />
          </div>
          <div className={styles.blogCategories}>
            <h3>Watershed Insights by category</h3>
            <InsightCats />
          </div>
        </section>
        <section className={styles.blogGrid} data-theme="brandGamma">
          <div className={styles.blogGridHeader}>
            <h2><Link to="/blog/product/">{blogs.heroTitleTech}</Link></h2>
            {blogs.descriptionTech && <p>{blogs.descriptionTech}</p>}
          </div>
          <ul className={styles.blogGridList}>
            {blogTech.map(({node: item}) =>
              <PostListItem {...item} type="blog/product" />
            )}
          </ul>
          <div className={styles.blogGridLink}>
            <FormattedLink linkUrl="/blog/product/" linkLabel="See all our Product & Tech blogs" />
          </div>
          <div className={styles.blogCategories}>
            <h3>Product & Tech by category</h3>
            <TechCats />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Blogs
